


































import { Component, Vue } from 'vue-property-decorator';
import Confirm from '@/components/profile/Confirm.vue';
import { UserEditForm } from '@/models/forms/UserForm';
import { UserRepository } from '@/repositories/UserRepository';
import { ResCurrentUser } from '@/gen';

@Component({
  components: {
    Confirm
  }
})
export default class P0016 extends Vue {
  form = {} as UserEditForm;
  isFetched = false;

  get userRepository() {
    return new UserRepository();
  }

  async created() {
    this.userRepository.getCurrentUser().then((res: ResCurrentUser) => {
      this.form = new UserEditForm(res);
      this.isFetched = true;
    });
  }
}
