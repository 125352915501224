







































import { Component, Vue } from 'vue-property-decorator';

Component.registerHooks(['beforeRouteEnter']);

@Component({
  components: {}
})
export default class P0121 extends Vue {
  beforeRouteEnter(to: any, from: any, next: any) {
    if (from.path === '/profile-edit') {
      next();
    } else {
      next('/profile');
    }
  }
}
