


















import { Component, Vue, Watch } from 'vue-property-decorator';
import ProfileEdit from '@/pages/UserForm/ProfileEdit.vue';
import ProfileConfirm from '@/pages/UserForm/ProfileConfirm.vue';
import { UserEditForm } from '@/models/forms/UserForm';
import { UserRepository } from '@/repositories/UserRepository';
import { ResCurrentUser } from '@/gen';
import { AxiosError } from 'axios';
import { OpCardRepository } from '@/repositories/OpCardRepository';

@Component({
  components: {
    ProfileEdit,
    ProfileConfirm
  }
})
export default class P0109 extends Vue {
  transitionName = '';
  digitalOpFlg = false;
  btnLoading = false;
  resErrMsg = '';
  form: any = '';

  get userRepository() {
    return new UserRepository();
  }

  get opCardRepo() {
    return new OpCardRepository();
  }

  @Watch('$auth.loading', { immediate: true })
  async initialize() {
    this.userRepository
      .getCurrentUser()
      .then((res: ResCurrentUser) => {
        this.form = new UserEditForm(res);
      })
      .catch(err => {
        this.$tokenErrHandler(err);
      });
  }

  get subPage() {
    switch (this.$route.hash) {
      case '':
        return ProfileEdit;
      case '#confirm':
        return ProfileConfirm;
      default:
        return ProfileEdit;
    }
  }

  back() {
    this.transitionName = 'slide-back';
    switch (this.$route.hash) {
      case '#confirm':
        this.$router.push({ hash: '' });
        break;
    }
  }

  next() {
    this.transitionName = 'slide-next';
    switch (this.$route.hash) {
      case '':
        this.$router.push({ hash: '#confirm' });
        break;
      case '#confirm':
        this.btnLoading = true;
        this.userRepository
          .updateUser(this.form)
          .then(() => {
            this.$store.commit('setLastName', this.form.lastName);
            this.$router.replace('/profile-complete');
          })
          .catch((err: AxiosError) => {
            this.handleSubmitErr(err);
          })
          .finally(() => {
            this.btnLoading = false;
          });
        break;
      default:
        this.$router.push('/sign-up');
        break;
    }
  }

  handleSubmitErr(err: AxiosError) {
    if (err.response) {
      switch (err.response.status) {
        case 400:
          this.resErrMsg = this.$msg.get('2000012');
          this.$router.push({ hash: '' });
          break;
        case 500:
          this.resErrMsg = this.$msg.get('2000011');
          this.$router.push({ hash: '' });
          break;
        case 503:
          {
            const maintenanceURL = `${window.location.origin}/maintenance.html`;
            window.location.href = maintenanceURL;
          }
          break;
        default:
          window.location.href = '/profile-edit';
      }
    }
  }
}
