












































































































































import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import NameForm from '@/components/profile/NameForm.vue';
import HiraNameForm from '@/components/profile/HiraNameForm.vue';
import GenderForm from '@/components/profile/GenderForm.vue';
import BirthForm from '@/components/profile/BirthForm.vue';
import JobForm from '@/components/profile/JobForm.vue';
import Phone1Form from '@/components/profile/Phone1Form.vue';
import Phone2Form from '@/components/profile/Phone2Form.vue';
import ZipCodeForm from '@/components/profile/ZipCodeForm.vue';
import PrefForm from '@/components/profile/PrefForm.vue';
import CityForm from '@/components/profile/CityForm.vue';
import TownForm from '@/components/profile/TownForm.vue';
import BuildingForm from '@/components/profile/BuildingForm.vue';
import OpRegisterForm from '@/components/OpRegisterForm/OpRegisterForm.vue';
import PromoEmailFlgForm from '@/components/profile/PromoEmailForm.vue';
import $ from 'jquery';
import utils from '@/common/utils.ts';
import { OpCardList } from '@/models/opcards/OpCard';
import { UserEditForm } from '@/models/forms/UserForm';

@Component({
  components: {
    NameForm,
    HiraNameForm,
    GenderForm,
    BirthForm,
    JobForm,
    Phone1Form,
    Phone2Form,
    ZipCodeForm,
    PrefForm,
    CityForm,
    TownForm,
    BuildingForm,
    OpRegisterForm,
    PromoEmailFlgForm
  }
})
export default class SignupEdit extends Vue {
  @Ref() readonly NameForm!: NameForm;
  @Ref() readonly HiraNameForm!: HiraNameForm;
  @Ref() readonly GenderForm!: GenderForm;
  @Ref() readonly BirthForm!: BirthForm;
  @Ref() readonly JobForm!: JobForm;
  @Ref() readonly Phone1Form!: Phone1Form;
  @Ref() readonly Phone2Form!: Phone2Form;
  @Ref() readonly ZipCodeForm!: ZipCodeForm;
  @Ref() readonly PrefForm!: PrefForm;
  @Ref() readonly CityForm!: CityForm;
  @Ref() readonly TownForm!: TownForm;
  @Ref() readonly BuildingForm!: BuildingForm;

  @Prop({ required: true })
  form!: UserEditForm;

  @Prop({ required: true, type: String })
  readonly resErrMsg!: string;

  get opcards() {
    return OpCardList.valueOf(
      this.$auth.user['https://one-odakyu.com/op_cards']
    );
  }

  zipErrMsg = '';

  async confirm() {
    if (await this.validateForm()) {
      this.$emit('next');
    } else {
      var custom = 0;
      const width = $(window).width() || 0;
      width >= 768 ? (custom = 360) : (custom = 180);
      $('.v-messages__message,.errFieldToScroll').each(function() {
        if ($(this).html() !== '') {
          const offset = $(this).offset() || { top: 0 };
          $('body, html').animate(
            {
              scrollTop: offset.top - custom
            },
            800
          );
          return false;
        }
      });
    }
  }

  async fetchAddress() {
    try {
      const res = await utils.fetchPostalCodeApi(
        this.form.zipcode1,
        this.form.zipcode2
      );
      this.zipErrMsg = '';
      this.form.pref = res.prefcode;
      this.form.city = res.ja.address1 + res.ja.address2;
      this.form.town = res.ja.address3;
      this.form.building = res.ja.address4;
    } catch (e) {
      this.zipErrMsg = this.$msg.get('2000005');
    }
  }

  async validateForm() {
    const result = Promise.all([
      this.NameForm.$validator.validateAll(),
      this.HiraNameForm.$validator.validateAll(),
      this.BirthForm.$validator.validateAll(),
      this.BirthForm.validateBirth(),
      this.JobForm.$validator.validateAll(),
      this.ZipCodeForm.$validator.validateAll(),
      this.Phone1Form.$validator.validateAll(),
      this.Phone1Form.validatePhone(),
      this.Phone2Form.$validator.validateAll(),
      this.Phone2Form.validatePhone2(),
      this.PrefForm.$validator.validateAll(),
      this.PrefForm.validatePref(),
      this.CityForm.$validator.validateAll(),
      this.TownForm.$validator.validateAll(),
      this.BuildingForm.$validator.validateAll()
    ]);
    return (await result).every(val => val);
  }

  @Emit()
  next() {
    return;
  }

  @Emit()
  back() {
    return;
  }
}
