









































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import OnceButton from '@/components/OnceButton.vue';
import Confirm from '@/components/profile/Confirm.vue';
import { UserEditForm } from '@/models/forms/UserForm';

@Component({
  components: {
    OnceButton,
    Confirm
  }
})
export default class ProfileConfirm extends Vue {
  @Prop({ required: true })
  readonly form!: UserEditForm;

  @Prop({ required: true })
  readonly btnLoading!: boolean;

  @Emit()
  next() {
    return;
  }

  @Emit()
  back() {
    return;
  }
}
